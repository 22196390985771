import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

document.addEventListener("DOMContentLoaded", function() {
  const body = document.querySelector("body");
  const env = body && body.dataset && body.dataset.env;
  const sentryAllDsn = {
    production:
      "https://08f7e21ab582414887fea5da9b8d0b96@o87027.ingest.sentry.io/4503959887872000",
    staging:
      "https://08f7e21ab582414887fea5da9b8d0b96@o87027.ingest.sentry.io/4503959887872000",
  };
  const sentryDsn = sentryAllDsn[env];

  if (sentryDsn)
    Sentry.init({
      dsn: sentryDsn,

      // Alternatively, use `process.env.npm_package_version` for a dynamic release version
      // if your build tool supports it.
      release: process.env.npm_package_version,
      integrations: [new BrowserTracing()],
      environnement: env,

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
});
