import "~/components/Sentry";
import $ from "jquery";

import Base from "~/components/Base";
import Analytics from "~/components/Analytics";
import HeaderNavbar from "~/components/HeaderNavbar";
import MobileMenu from "~/components/MobileMenu";
import Dropdown from "~/components/Dropdown";

export default class App extends Base {
  handleContactLink = () => {
    const $contactLink = $("a.footer-contact-link");
    if ($contactLink.length > 0) {
      $("a.footer-contact-link").attr("href", "mai" + "lto:" + "contact" + "@" + "infocession.fr");
    }
  };

  encodeQueryData = (data) => {
    const output = [];

    Object.keys(data).map((key) => output.push(encodeURIComponent(key) + "=" + encodeURIComponent(data[key])));
    return output.join("&");
  };

  handleHiddenEmails = () => {
    $("a.hidden-email").each((index, item) => {
      const $link = $(item);
      const html = $link.data("link-body") || $link.data("login") + "@" + $link.data("host");

      let href = "mai" + "lto:" + $link.data("login") + "@" + $link.data("host");
      let subject = $link.data("subject");
      let body = $link.data("body");

      if (subject || body) {
        const parameters = {};
        if (subject) parameters.subject = $link.data("subject");
        if (body) parameters.body = $link.data("body");
        href += "?" + this.encodeQueryData(parameters);
      }

      $link.attr("href", href);
      $link.html(html);
    });
  };

  handleImageLinks = () => {
    $(".image-link[data-href]").click((e) => {
      e.preventDefault();
      document.location.href = $(e.currentTarget).data("href");
    });
  };

  handleTokenLogin = () => {
    if ($("#user-logged-in-using-token").length === 0) return;
    window.analytics.trackEvent("autoConnexion");
  };

  handleDataConfirm = () => {
    $("[data-confirm]").each((index, item) => {
      const $item = $(item);
      const text = $item.data("confirm");
      $item.click((e) => confirm(text));
    });
  };

  handleToggleCollapse = () => {
    $("[data-toggle-collapse]").each((index, selector) => {
      const $item = $(selector);

      $item.click((e) => {
        e.preventDefault();
        const $target = $($item.data("toggle-collapse"));

        if ($target.is(":visible")) {
          $target.slideUp();
        } else {
          $target.slideDown();
        }
      });
    });
  };

  handleToggleFields = () => {
    $("[data-toggle]").each((index, selector) => {
      const $item = $(selector);
      const toggleKey = $item.data("toggle");
      const isReversed = $item.data("toggle-reversed");
      const $target = $(`[data-toggled-by="${toggleKey}"]`);

      $item.change((e) => {
        e.preventDefault();

        if ($item.prop("checked")) isReversed ? $target.slideUp() : $target.slideDown();
        else isReversed ? $target.slideDown() : $target.slideUp();
      });

      const checked = $item.prop("checked");
      if ((checked && isReversed) || (!checked && !isReversed)) $target.hide();
    });
  };

  handleDragAndDrop = () => {
    $(".ic-drag-and-drop-container").each((index, selector) => {
      const $container = $(selector);
      const $items = $container.find("[draggable]");
      let $dragSource = undefined;

      $items.on("dragstart", (e) => {
        const $item = $(e.currentTarget);

        $item.css("opacity", "0.4");
        $dragSource = $item;
      });

      $items.on("dragend", (e) => $(e.currentTarget).css("opacity", "1.0"));

      $items.on("dragover", (e) => {
        e.preventDefault();
        return false;
      });

      $items.on("drop", (e) => {
        const $item = $(e.currentTarget);
        const $prevItem = $item.prevAll("[draggable]");

        e.stopPropagation();

        if ($prevItem[0] === $dragSource[0]) $item.after($dragSource);
        else if ($item[0] !== $dragSource[0]) $item.before($dragSource);

        return false;
      });
    });
  };

  render() {
    window.analytics = new Analytics();
    window.analytics.trackPage();

    new HeaderNavbar("#fixedNavbar").run();
    new MobileMenu().run();
    new Dropdown().run();

    this.handleContactLink();
    this.handleHiddenEmails();
    this.handleImageLinks();
    this.handleTokenLogin();
    this.handleDataConfirm();
    this.handleToggleCollapse();
    this.handleToggleFields();
    this.handleDragAndDrop();
  }
}
