import $ from "jquery";

export default class Dropdown {
  constructor() {
    this.$container = $(".ic-dropdown");
    this.$links = this.$container.find(".ic-dropdown-link");
    this.$dropdowns = this.$container.find(".ic-dropdown-content");
    this.linkHover = false;
    this.dropdownHover = false;
  }

  run = () => {
    this.$dropdowns.mouseenter(this.dropdownHoverOn);
    this.$dropdowns.mouseleave(this.dropdownHoverOff);
    this.$links.mouseenter(this.linkHoverOn);
    this.$links.mouseleave(this.linkHoverOff);
  };

  dropdownHoverOn = () => (this.dropdownHover = true);
  dropdownHoverOff = () => {
    this.dropdownHover = false;
    this.linkHover = false;
  };

  linkHoverOn = (e) => {
    const $link = $(e.currentTarget);
    const $dropdown = $link
      .parents(".ic-dropdown")
      .find(".ic-dropdown-content");

    if (this.isMobile() && $link.hasClass("ic-mobile-disabled")) return;

    this.linkHover = true;
    this.$dropdowns.hide();
    this.$links.removeClass("open");

    $dropdown.show();
    $link.addClass("open");
    setTimeout(this.closeDropdown($dropdown, $link), 2000);
  };

  closeDropdown = ($dropdown, $link) => () => {
    if (!this.linkHover && !this.dropdownHover) {
      $dropdown.hide();
      $link.removeClass("open");
    } else {
      setTimeout(this.closeDropdown($dropdown, $link), 2000);
    }
  };

  linkHoverOff = () => (this.linkHover = false);
  isDesktop = () => $("#desktopTest").is(":visible");
  isMobile = () => $("#desktopTest").is(":hidden");
}
