import $ from "jquery";

export default class Base {
  constructor(element) {
    this.element = element;
    this.analytics = window.analytics;
  }

  lazyloaded = (element) => {
    element.classList.add("ic-lazyloaded");
  };

  isDesktop = () => $("#desktopTest").is(":visible");
  isMobile = () => $("#desktopTest").is(":hidden");
  isLoggedIn = () => $("body").hasClass("logged-in");
  isProduction = () => $("body").data("env") === "production";

  isDevRun = ({ key } = {}) => {
    const params = new URLSearchParams(window.location.search);

    return params.has(key || "dev") && $("body").data("env") === "development";
  };

  devStep = () => {
    const params = new URLSearchParams(window.location.search);

    return params.has("6")
      ? 6
      : params.has("5")
      ? 5
      : params.has("4")
      ? 4
      : params.has("3")
      ? 3
      : params.has("2")
      ? 2
      : params.has("1")
      ? 1
      : 0;
  };

  responsive = (callback) => {
    let desktop = this.isDesktop();

    $(window).resize(() => {
      const newDesktop = this.isDesktop();

      if ((desktop && !newDesktop) || (!desktop && newDesktop)) {
        desktop = newDesktop;
        callback({ desktop, mobile: !desktop });
      }
    });

    callback({ desktop, mobile: !desktop });
  };

  scrollToTop = (speed = 500) => $("html, body").stop().animate({ scrollTop: 0 }, speed, "swing");

  scrollTo = ({ $target, speed }) =>
    $("html, body")
      .stop()
      .animate({ scrollTop: $target.offset().top - 150 }, speed || 500, "swing");

  focusTextInput = ($element) => $element.find('input[type="text"]').select().focus();
}
