import $ from "jquery";

import AnalyticsWrapper from "analytics";
import googleTagManager from "@analytics/google-tag-manager";

const enterpriseValueMapping = {
  advisor: {
    0: "1",
    1: "2",
    2: "4",
    3: "8",
    4: "10",
    5: "10",
    6: "10",
    7: "10",
  },
  investor: {
    0: "0",
    1: "1",
    2: "2",
    3: "4",
    4: "8",
    5: "10",
    6: "10",
    7: "10",
  },
};

export default class Analytics {
  constructor() {
    this.wrapper = this.isActive()
      ? AnalyticsWrapper({
          app: "infocession",
          plugins: [
            googleTagManager({
              containerId: "GTM-MHCJMPQ",
            }),
          ],
        })
      : undefined;
  }

  getEnv = () => {
    const body = document.querySelector("body");
    return (body && body.dataset && body.dataset.env) || "development";
  };

  isProduction = () => this.getEnv() === "production";
  isDevelopment = () => this.getEnv() === "development";

  isActive = () => this.isProduction(); // Temporary, please disable it in development

  // If user is logged in, we don't send "interactionValue", as it is a useful data only for new leads
  isLoggedIn = () => $("body").hasClass("logged-in");

  trackPage = () => {
    if (this.isActive()) this.wrapper.page();
  };

  trackableCompanyName = (companyName) => companyName !== "PICARD SURGELES" && companyName !== "Picard Surgelés";

  trackEvent = (event, payload = {}, options = { forceInteractionValue: false }) => {
    const addInteractionValue = options.forceInteractionValue || !this.isLoggedIn();

    const filteredPayload = Object.keys(payload).reduce((output, key) => {
      if (addInteractionValue || (!addInteractionValue && key !== "interactionValue")) output[key] = payload[key];
      return output;
    }, {});

    if (this.isActive()) this.wrapper.track(event, filteredPayload);
    else console.log("trackEvent", event, payload, filteredPayload);
  };

  advisorEnterpriseValueMapping = (enterpriseValue) => enterpriseValueMapping.advisor[enterpriseValue];

  investorEnterpriseValueMapping = (enterpriseValue) => enterpriseValueMapping.investor[enterpriseValue];

  ebeMapping = (ebe) => {
    if (ebe < 300000) return 0;
    if (ebe < 600000) return 2;
    if (ebe < 1000000) return 5;
    return 10;
  };
}
