import $ from "jquery";
import Base from "~/components/Base";

export default class MobileMenu extends Base {
  constructor() {
    super();
    this.$button = $(".header-v2-container .mobile-menu-icon");
    this.isOpen = false;
    this.$openIcon = this.$button.find(".open-icon");
    this.$closeIcon = this.$button.find(".close-icon");

    this.$menuContainer = $(".header-v2-container .mobile-menu-container");
    this.$menu = this.$menuContainer.find(".mobile-menu");
    this.$links = this.$menuContainer.find(".ic-dropdown-link");
    this.$dropdowns = this.$menuContainer.find(".ic-dropdown-content");
  }

  run = () => {
    this.$button.click(this.onButtonClick);
    this.$links.click(this.onMenuLinkClick);
    this.$menuContainer.click(this.onMenuContainerClick);

    if (this.isDevRun({ key: "menu" })) setTimeout(() => this.open(), 500)
  };

  onButtonClick = (e) => {
    e.preventDefault();

    if (this.isDesktop()) {
      return;
    }

    this.isOpen = !this.isOpen;

    if (this.isOpen) {
      this.open();
    } else {
      this.close();
    }
  };

  onMenuLinkClick = (e) => {
    if (this.isDesktop()) {
      return;
    }

    // When clicking on a link, we don't want to close the dropdown.
    if ($(e.target).parents(".dropdown-menu-item").length > 0) {
      return;
    }
    e.preventDefault();

    const $link = $(e.currentTarget);
    const $dropdown = $link
      .parents(".ic-dropdown")
      .find(".ic-dropdown-content");

    this.$dropdowns
      .filter((i, e) => e !== $dropdown[0])
      .each((i, dropdown) => {
        $(dropdown).slideUp(300);
        $(dropdown)
          .parents(".menu-item")
          .find(".menu-label svg")
          .removeClass("open");
      });

    $dropdown
      .parents(".menu-item")
      .find(".menu-label svg")
      .toggleClass("open", $dropdown.is(":hidden"));

    if ($dropdown.is(":visible")) {
      $dropdown.slideUp(300);
    } else {
      $dropdown.slideDown(300);
    }
  };

  onMenuContainerClick = (e) => {
    if (e.target !== e.currentTarget) {
      return;
    }

    e.preventDefault();
    this.close();
  };

  open = () => {
    this.$menuContainer.show().addClass("open");
    this.$openIcon.fadeOut(150, () => this.$closeIcon.fadeIn(150));
    this.$menu.slideDown(300);
  };

  close = () => {
    this.$menu.slideUp(300, () => {
      this.$menuContainer.removeClass("open");
      setTimeout(() => this.$menuContainer.hide(), 300);
    });
    this.$closeIcon.fadeOut(150, () => this.$openIcon.fadeIn(150));
  };
}
