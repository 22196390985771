import $ from "jquery";

export default class HeaderNavbar {
  constructor(selector) {
    this.$element = $(selector);
    this.displayed = false;
    this.enabled = this.isDesktop();
    this.analytics = window.analytics;

    this.navbarsHeight =
      $(".primary-navbar-container").height() +
      $(".secondary-navbar-container").height();
  }

  run = () => {
    $(window).scroll(this.onScroll);
    this.onScroll();
    this.$element.find(".cta-button").click(this.trackEvent);
  };

  onScroll = () => {
    if (!this.ensureFixedNavbarAllowed()) {
      return;
    }

    const shouldDisplay = window.scrollY >= this.navbarsHeight;

    if (shouldDisplay && !this.displayed) {
      this.$element.slideDown(200);
      this.displayed = true;
    } else if (!shouldDisplay && this.displayed) {
      this.$element.slideUp(200);
      this.displayed = false;
    }
  };

  ensureFixedNavbarAllowed = () => {
    if (this.enabled && this.isMobile()) {
      this.enabled = false;
    } else if (!this.enabled && this.isDesktop()) {
      this.enabled = true;
    }

    if (!this.enabled) {
      this.cancelFixedNavbar();
    }

    return this.enabled;
  };

  cancelFixedNavbar = () => {
    if (this.displayed) {
      this.displayed = false;
      this.$element.hide();
    }
  };

  isDesktop = () => $("#desktopTest").is(":visible");
  isMobile = () => $("#desktopTest").is(":hidden");

  trackEvent = () => {
    this.analytics.trackEvent("clickEvaluerCTAGoNiv0", {
      clickPlace: "sticky",
    });
  };
}
